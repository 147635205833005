const getCurrentEnvironment = () => {
  if (process.env.NEXT_PUBLIC_API_BASE_URL === 'https://api2-release.altisai.com') return 'staging'
  if (process.env.NEXT_PUBLIC_API_BASE_URL === 'https://api2.altisai.com') return 'production'
  return 'development'
}


const config = {
  googleMeasurementID: 'G-MH5TGH0N91',
  sentryDSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
  currentEnvironment: getCurrentEnvironment()
}

export default config