import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%'
        },
        body: {
          height: '100%'
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 980,
      lg: 1114,
      xl: 1440
    }
  },
  palette: {
    workoutSummary: {
      main: '#091548',
      accent: '#09d7ff',
      textSecondary: '#4a60bb'
    },
    background: {
      paper: '#fafafb',
      default: '#fff'
    },
    wrapper: {
      main: '#010040',
      contrastText: '#b1c2e6',
      contrastTextLight: '#cbdbff'
    },
    header: {
      main: '#010040'
    },
    footer: {
      main: '#121212',
      contrastText: 'rgba(235, 237, 242, 0.58)'
    },
    primary: {
      main: '#44CAE7',
      contrastText: '#fff'
    },
    secondary: {
      main: '#3D80BA',
      contrastText: '#fff'
    },
    text: {
      primary: '#000',
      secondary: '#fff'
    }
  },
  typography: {
    fontFamily: '"Nunito Sans", "Helvetica", sans-serif',
    fontSize: 14,
    fontWeight: 500,
    fontDisplay: 'swap'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          fontWeight: 700,
          textTransform: 'none'
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color'
          }
        }
      }
    }
  }
})

export default theme
