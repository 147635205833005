import * as React from 'react'
import Image from 'next/image'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import LoaderImg from '../../public/preloader.gif'

const LoadingWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100%',
  background: '#000',
  position: 'relative',
  zIndex: 1000,
  transitionProperty: 'all',
  transitionDuration: '1s',
  transitionDelay: '1s'
}))

export default function Loading () {
  return (
    <>
      <LoadingWrap>
        <Image
          src={LoaderImg}
          alt='Altis'
          width={100}
          height={100}
        />
      </LoadingWrap>
    </>
  )
}
