import * as React from 'react'
import Head from 'next/head'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider } from '@emotion/react'
import theme from '../src/theme'
import createEmotionCache from '../src/createEmotionCache'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import Loading from '../components/common/Loading'
import { SnackbarProvider } from 'notistack'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

export default function MyApp(props) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    router
  } = props
  const pageRouter = useRouter()
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const handleStart = (url) => {
      url !== pageRouter.pathname ? setLoading(true) : setLoading(false)
    }
    const handleComplete = (url) => setLoading(false)

    pageRouter.events.on('routeChangeStart', handleStart)
    pageRouter.events.on('routeChangeComplete', handleComplete)
    pageRouter.events.on('routeChangeError', handleComplete)
  }, [pageRouter])

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Altis</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="icon" passhref="true" href="/favicon.ico" />
        <style type='text/css'>
          {`html {
            scroll-behavior: smooth;
          }`}
        </style>
      </Head>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {!loading ? (
            <motion.div
              key={router.route}
              initial="initial"
              animate="animate"
              variants={{
                initial: { opacity: 0 },
                animate: { opacity: 1 }
              }}
            >
              <Component {...pageProps} />
            </motion.div>
          ) : (
            <Loading />
          )}
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}
